<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/JGGK' }">机构概况</el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">

      <div class="title">
        国家放射影像专业医疗质量控制中心<br>
        专家委员会委员名单
      </div>
      <el-table :data="list" style="width: 100%">
        <el-table-column label="委员类别" prop="type">
        </el-table-column>
        <el-table-column label="姓名" prop="name">
        </el-table-column>
        <el-table-column label="所在单位" prop="hospital">
        </el-table-column>
        <el-table-column label="职称" prop="position">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "JGGK",
  data() {
    return {
      list: [
        {
          type: "主任委员",
          name: "金征宇",
          hospital: "中国医学科学院北京协和医院",
          position: "主任医师",
        },
        {
          type: "副主任委员",
          name: "陈　敏",
          hospital: "北京医院",
          position: "主任医师",
        },
        {
          type: "副主任委员",
          name: "薛华丹",
          hospital: "中国医学科学院北京协和医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "王光彬",
          hospital: "山东省医学影像学研究所",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "王培军",
          hospital: "同济大学附属同济医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "卢光明",
          hospital: "中国人民解放区东部战区总医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "卢　洁",
          hospital: "首都医科大学宣武医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "付海鸿",
          hospital: "中国医学科学院北京协和医院",
          position: "高级工程师",
        },
        {
          type: "委员",
          name: "刘士远",
          hospital: "中国人民解放军海军军医大学第二附属医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "刘　军",
          hospital: "中南大学湘雅二医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "刘怀军",
          hospital: "河北医科大学第二医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "严福华",
          hospital: "上海交通大学医学院附属瑞金医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "李　欣",
          hospital: "天津市儿童医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "宋　伟",
          hospital: "中国医学科学院北京协和医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "宋　彬",
          hospital: "四川大学华西医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "张惠茅",
          hospital: "吉林大学第一医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "张　辉",
          hospital: "山西医科大学第一医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "郑传胜",
          hospital: "华中科技大学同济医学院附属协和医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "赵心明",
          hospital: "中国医学科学院肿瘤医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "侯　阳",
          hospital: "中国医科大学附属盛京医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "洪　楠",
          hospital: "北京大学人民医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "高剑波",
          hospital: "郑州大学第一附属医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "曹代荣",
          hospital: "福建医科大学附属第一医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "梁长虹",
          hospital: "广东省人民医院",
          position: "主任医师",
        },
        {
          type: "委员",
          name: "鲜军舫",
          hospital: "首都医科大学附属北京同仁医院",
          position: "主任医师",
        },
        {
          type: "秘书",
          name: "于　静",
          hospital: "中国医学科学院北京协和医院",
          position: "职员",
        },
        {
          type: "秘书",
          name: "孙　昊",
          hospital: "中国医学科学院北京协和医院",
          position: "主任医师",
        },
      ]
    }
  }
}
</script>

<style scoped>
.wrap {
  width: 70%;
  margin: 0 auto;
}

.title {
  text-align: center;
  font-size: 20px;
  margin: 20px;
  font-weight: 500;
}
</style>